/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
 
 /* Core CSS required for Ionic components to work properly */
 @import "~@ionic/angular/css/core.css";
 
 /* Basic CSS for apps built with Ionic */
 @import "~@ionic/angular/css/normalize.css";
 @import "~@ionic/angular/css/structure.css";
 @import "~@ionic/angular/css/typography.css";
 @import '~@ionic/angular/css/display.css';
 
 /* Optional CSS utils that can be commented out */
 @import "~@ionic/angular/css/padding.css";
 @import "~@ionic/angular/css/float-elements.css";
 @import "~@ionic/angular/css/text-alignment.css";
 @import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~leaflet/dist/leaflet.css';

// ion-input {
// 	background-color: grey;
// 	color: white;
// 	font-size: large;
// }

:root {
    --ion-font-family: 'helvetica'
}

:root {
    ion-input {
        --background: grey;
		--color: white;
        --padding-start: 100px;
        --padding-end: 100px;
        border-radius: 40px;
      
    }
}
// ion-content {
//     --background: #34B0D2 url('assets/kimppu.png') no-repeat center;
//   background-size: cover;
// }

ion-content {
    --background: #f0f4f0; // f0f4f0
  }

ion-toolbar, ion-title, .keskitetty {
      align-items: center;
      justify-content: center;
    }
    
    * {
        font-family: 'helvetica';
        font-size: small;
    }
    
    @font-face {
        font-family: 'helvetica';
        src: url();
    }
    @media (prefers-color-scheme: dark) {
        ion-content {
            --background: #111a22; // f0f4f0
          }
       
    }